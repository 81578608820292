import './App.css';
import Portfolio from './pages/Portfolio';

function App() {
  return (
    <Portfolio />
  );
}

export default App;
